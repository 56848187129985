<template>
  <div id="pageNotFound">
  <!-- <Home></Home> -->
  <div class="error_outer"> <h1>Error:404 Page not found</h1></div>
  </div>
</template>

<script>

export default {
  name: 'pageNotFound',
 

  components: {
    
  }
}
</script>

<style>

</style>
